import { Validatable } from '@/core/models';
import { AxiosFactory } from '@/core/services/common';
import {
  DashboardApiFactory,
  Configuration,
  AccountApiFactory,
  ArchiveApiFactory,
  AutoSaveApiFactory,
  ExportApiFactory,
  ExposuresApiFactory,
  IllnessesApiFactory,
  InjuriesApiFactory,
  OrganisationsApiFactory,
  PlayersApiFactory,
  ResourcesApiFactory,
  RolesApiFactory,
  SettingsApiFactory,
  StudiesApiFactory,
  UsersApiFactory,
  IllnessQualityControlApiFactory,
  InjuryQualityControlApiFactory,
  CommunityExposuresApiFactory,
  CommunityInjuriesApiFactory,
  CatastrophicInjuriesApiFactory,
  SquadsApiFactory,
  ReporterAccessApiFactory,
  ApiKeyApiFactory,
} from '@/core/webapi';
import { AxiosError } from 'axios';

function buildArgs() {
  return [new Configuration({ basePath: process.env.VUE_APP_WEBAPI_URL }), undefined, AxiosFactory.create()] as any[];
}

export const ApiService = {
  dashboard: () => DashboardApiFactory(...buildArgs()),
  account: () => AccountApiFactory(...buildArgs()),
  archive: () => ArchiveApiFactory(...buildArgs()),
  autoSave: () => AutoSaveApiFactory(...buildArgs()),
  export: () => ExportApiFactory(...buildArgs()),
  exposures: () => ExposuresApiFactory(...buildArgs()),
  communityExposures: () => CommunityExposuresApiFactory(...buildArgs()),
  illnesses: () => IllnessesApiFactory(...buildArgs()),
  illnessQualityControl: () => IllnessQualityControlApiFactory(...buildArgs()),
  injuries: () => InjuriesApiFactory(...buildArgs()),
  injuryQualityControl: () => InjuryQualityControlApiFactory(...buildArgs()),
  communityInjuries: () => CommunityInjuriesApiFactory(...buildArgs()),
  catastrophicInjuries: () => CatastrophicInjuriesApiFactory(...buildArgs()),
  organisations: () => OrganisationsApiFactory(...buildArgs()),
  players: () => PlayersApiFactory(...buildArgs()),
  resources: () => ResourcesApiFactory(...buildArgs()),
  roles: () => RolesApiFactory(...buildArgs()),
  settings: () => SettingsApiFactory(...buildArgs()),
  studies: () => StudiesApiFactory(...buildArgs()),
  users: () => UsersApiFactory(...buildArgs()),
  squads: () => SquadsApiFactory(...buildArgs()),
  reporterAccess: () => ReporterAccessApiFactory(...buildArgs()),
  apiKey: () => ApiKeyApiFactory(...buildArgs()),
};

declare global {
  interface Promise<T> {
    /**
     * Attach validation errors and validation summary properties to a model.
     * @param model - Model to attach these properties to.
     */
    handleValidationErrors<TResult1 = T, TResult2 = never>(model: any): Promise<TResult1 | TResult2>;

    /**
     * Extracts data from a response and automatically sets validation
     * properties to defaults (empty object and empty array).
     */
    getDataAndDefaultValidationProps<TResult1 = T, TResult2 = never>(): Promise<TResult1 | TResult2>;
  }
}

Promise.prototype.handleValidationErrors = function(model: any) {
  return this.then(
    response => Promise.resolve(response),
    error => {
      if (error?.response?.status === 422) {
        model.modelState = error.response.data?.modelState;
      }
      return Promise.reject(error);
    },
  );
};

Promise.prototype.getDataAndDefaultValidationProps = function() {
  return this.then(
    response => {
      const data = response.data;
      if (!data) {
        return Promise.resolve(null);
      }

      data.modelState = {};
      return Promise.resolve({ ...data });
    },
    error => Promise.reject(error),
  );
};

export function getFirstValidationErrorMessage(error: AxiosError) {
  const modelState = (error.response?.data as Validatable)?.modelState;
  return modelState?.[Object.keys(modelState)[0]]?.[0];
}
