import { Vue } from 'vue-property-decorator';

import { ApiService } from '@/core/services';
import { LoginModel, TokenModel } from '@/core/webapi';

export const AccountService = new Vue({
  methods: {
    async login(model: LoginModel) {
      const response = (
        await ApiService.account()
          .login(model)
          .handleValidationErrors(model)
      ).data;
      this.setToken(response);
      return response;
    },
    logout() {
      this.$auth.logout();
      this.$eventHub.$emit('USER_UPDATED');
    },
    async refreshToken(organisationId?: number) {
      const response = (await ApiService.account().refreshToken(organisationId)).data;
      this.setToken(response);
      return response;
    },
    setToken(response: TokenModel) {
      this.$auth.setToken({ data: response });
      this.$eventHub.$emit('USER_UPDATED');
    },
  },
});
