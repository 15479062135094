import { Vue } from 'vue-property-decorator';
import * as moment from 'moment';
import _ from 'lodash';

// If token is only valid for less than 2 minutes, refresh it
const refreshThreshold = 2;

export const AuthTokenService = new Vue({
  methods: {
    getRemainingTokenDuration() {
      const payload = this.$auth.getPayload();

      if (!payload) {
        return moment.duration(0);
      }

      const expiryTime = moment.unix(payload.exp).utc();
      const now = moment.utc();
      const diff = moment.duration(expiryTime.diff(now));

      return diff;
    },
    getRemainingTokenSeconds() {
      return Number(
        this.getRemainingTokenDuration()
          .asSeconds()
          .toFixed(0),
      );
    },
    getRemainingTokenMinutes() {
      return this.getRemainingTokenDuration().asMinutes();
    },
    emitTokenAlmostExpiredIfNecessary() {
      const diff = this.getRemainingTokenMinutes();

      if (diff < refreshThreshold && diff > 0) {
        this.$eventHub.$emit('TOKEN_ABOUT_TO_EXPIRE');
      }
    },
  },
});
