import Vue from 'vue';
import Vuex from 'vuex';
import { VueSettings } from '@/core/webapi';

Vue.use(Vuex);

const defaultSettings = { timestamp: new Date() as any, reporterOrgs: [], guidances: [] };

export default new Vuex.Store({
  state: {
    settings: new VueSettings(defaultSettings),
  },
  mutations: {
    setSettings(state, settings: VueSettings) {
      state.settings = settings ?? new VueSettings(defaultSettings);
    },
  },
  actions: {},
  modules: {},
  getters: {
    getSettings(state) {
      return state.settings;
    },
  },
});
