import { render, staticRenderFns } from "./NoReporterOrganisationMessageCpt.vue?vue&type=template&id=01a16c4f&scoped=true&"
import script from "./NoReporterOrganisationMessageCpt.vue?vue&type=script&lang=ts&"
export * from "./NoReporterOrganisationMessageCpt.vue?vue&type=script&lang=ts&"
import style0 from "./NoReporterOrganisationMessageCpt.vue?vue&type=style&index=0&id=01a16c4f&lang=scss&scoped=true&"
import style1 from "./NoReporterOrganisationMessageCpt.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "01a16c4f",
  null
  
)

export default component.exports