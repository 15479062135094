export const reportsIllnessesRoutes = [
  {
    path: '/illnesses',
    name: 'illnesses',
    component: () => import(/* webpackChunkName: "illnesses" */ './IllnessGridView.vue'),
  },
  {
    path: '/illnesses/new/:playerId',
    name: 'illnessNew',
    component: () => import(/* webpackChunkName: "illnesses" */ './IllnessReporterFormView.vue'),
  },
  {
    path: '/illnesses/new/:autoSaveId/draft',
    name: 'illnessNewDraft',
    component: () => import(/* webpackChunkName: "illnesses" */ './IllnessReporterFormView.vue'),
  },
  {
    path: '/illnesses/:illnessId',
    name: 'illnessDetails',
    component: () => import(/* webpackChunkName: "illnesses" */ './IllnessDetailsView.vue'),
  },
  {
    path: '/illnesses/:illnessId/edit',
    name: 'illnessEdit',
    component: () => import(/* webpackChunkName: "illnesses" */ './IllnessReporterFormView.vue'),
  },
  {
    path: '/illnesses/:illnessId/review',
    name: 'illnessReview',
    component: () => import(/* webpackChunkName: "illnesses" */ './IllnessAnalystFormView.vue'),
  },
];
