export const adminRegistrationRoutes = [
  {
    path: '/admin/reporter-access-requests',
    name: 'reporterAccessRequests',
    component: () => import(/* webpackChunkName: "admin" */ './ReporterAccessRequestsGridView.vue'),
  },
  {
    path: '/admin/reporter-access-requests/:requestId/review',
    name: 'Reporter Access Request - Review',
    component: () => import(/* webpackChunkName: "admin" */ './ReporterAccessRequestReviewView.vue'),
  },
];
