import Vue from 'vue';
import Router from 'vue-router';

import { RoutingService } from '@/core/services';
import { authRoutes } from '@/modules/auth/routes';
import { playersRoutes } from '@/modules/players/routes';
import { reportsRoutes } from '@/modules/reports/routes';
import { adminRoutes } from '@/modules/admin/routes';
import { accessRequestsRoutes } from '@/modules/access-requests/routes';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [...authRoutes, ...playersRoutes, ...reportsRoutes, ...adminRoutes, ...accessRequestsRoutes],
});

router.beforeEach((to, from, next) => {
  const anonymousOnly = to.matched.some(record => record.meta.anonymousOnly);
  const auth = router.app.$auth;
  const isAuthenticated = auth.isAuthenticated();
  const payload = auth.getPayload();

  // For duplicate redirects
  // SEE: https://stackoverflow.com/a/59431264/413785

  if (!anonymousOnly) {
    if (!isAuthenticated) {
      // If a non-anonymous-only route and user not authenticated, redirect to login
      next(`login${RoutingService.getEncodedRedirectUriSuffix(to?.fullPath)}`);
      return;
    } else {
      if (payload) {
        const enforce2Fa = JSON.parse(payload.enforce2Fa);
        const is2FaEnabled = JSON.parse(payload.is2FaEnabled);

        if (enforce2Fa && !is2FaEnabled && to.name !== 'authenticator') {
          next('authenticator');
          return;
        }
      }
    }
    // If non-anonymous-only route and user authenticated, don't do anything
  } else {
    if (isAuthenticated) {
      // If anonymous-only route, and user authenticated, redirect to home,
      // we don't want to bother the user with auth screens
      next(RoutingService.getDefaultRoute(payload));
      return;
    }
  }

  if (isAuthenticated && !to.name) {
    next(RoutingService.getDefaultRoute(payload));
    return;
  }

  next();
});

export default router;
