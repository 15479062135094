export const reportsExposuresRoutes = [
  {
    path: '/exposures',
    name: 'exposures',
    component: () => import(/* webpackChunkName: "exposures" */ './EliteExposuresGridView.vue'),
  },
  {
    path: '/exposures/calendar',
    name: 'exposuresCalendar',
    component: () => import(/* webpackChunkName: "exposures" */ './ExposureCalendarView.vue'),
  },
  {
    path: '/c/match-exposures',
    name: 'matchExposures',
    component: () => import(/* webpackChunkName: "exposures" */ './CommunityExposureMatchesGridView.vue'),
  },
  {
    path: '/c/training-exposures',
    name: 'trainingExposures',
    component: () => import(/* webpackChunkName: "exposures" */ './CommunityExposureTrainingGridView.vue'),
  },
  // NOTE: For now we're disabling view and edit,
  // to test out only allowing users to use the grid for exposures
  //
  // {
  //   path: '/exposures/new',
  //   name: 'exposureNew',
  //   component: () => import(/* webpackChunkName: "exposures" */ './ExposureFormView.vue'),
  // },
  // {
  //   path: "/exposures/new/:autoSaveId/draft",
  //   name: "exposureNewDraft",
  //   component: () => import(/* webpackChunkName: "exposures" */ "./ExposureFormView.vue"),
  // },
  // {
  //   path: "/exposures/:exposureId/edit",
  //   name: "exposureEdit",
  //   component: () => import(/* webpackChunkName: "exposures" */ "./ExposureFormView.vue"),
  // },
  // {
  //   path: "/exposures/:exposureId",
  //   name: "exposureDetails",
  //   component: () => import(/* webpackChunkName: "exposures" */ "./ExposureDetailsView.vue"),
  // },
];
