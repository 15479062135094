import { Vue } from 'vue-property-decorator';
import { FileDownloadModel } from '@/core/models';

export const DownloadProvider = new Vue({
  methods: {
    downloadAsCsv(data: string, filename: string) {
      // SEE: https://stackoverflow.com/a/63925967/413785
      const heading = 'data:text/csv;charset=utf-8,';
      const content = encodeURIComponent(data.replaceAll('&#39;', "'")); // &#39; ->  apostrophe
      const csv = `${heading}${content}`;

      const link = document.createElement('a');
      link.setAttribute('href', csv);
      link.setAttribute('download', `${filename}.csv`);
      link.click();
    },
    downloadResponse(response: FileDownloadModel) {
      const blob = new Blob([Utils.getArrayBuffer(atob(response.data))], { type: response.contentType });
      DownloadProvider.download(blob, response.fileName);
    },
    download(blob: Blob, filename: string) {
      const a = document.createElement('a');
      // IE
      if ((window.navigator as any).msSaveOrOpenBlob) {
        a.onclick = evx => {
          (window.navigator as any).msSaveOrOpenBlob(blob, filename);
          this.notify('Success', 'File successfully downloaded.', 'success');
        };
        a.click();
      } else {
        // Chrome and safari
        a.href = URL.createObjectURL(blob);
        a.download = filename;
        a.click();
        window.URL.revokeObjectURL(a.href);
        this.notify('Success', 'File successfully downloaded.', 'success');
      }
    },
    notify(title: string, message: string, type: any) {
      this.$notify({
        title,
        message,
        type,
      });
    },
  },
});

export class Utils {
  static getArrayBuffer(data: string) {
    const buf = new ArrayBuffer(data.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i !== data.length; ++i) {
      // eslint-disable-next-line no-bitwise
      view[i] = data.charCodeAt(i) & 0xff;
    }
    return buf;
  }
}
