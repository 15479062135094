import { StudyTypes } from '../constants';

export const RoutingService = {
  getDefaultRoute(authToken: any) {
    const redirectUri = this.getDecodedRedirectUri();
    if (redirectUri) {
      return redirectUri;
    }

    const roles = authToken.userRoles;

    if (roles.includes('StudyAdmin') || roles.includes('MedicalAdmin') || roles.includes('Admin')) {
      return '/admin';
    } else if (roles.includes('Reporter')) {
      if (!authToken.orgId) {
        return '/no-access';
      }

      const anyReporterStudies = JSON.parse(authToken?.anyStudies?.toLowerCase() ?? false);
      if (!anyReporterStudies) {
        return '/profile';
      }

      const studyTypeId = JSON.parse(authToken.stypeId ?? null);

      if (studyTypeId === StudyTypes.Elite) {
        if (JSON.parse(authToken.allowIaI)) {
          return '/players';
        } else if (JSON.parse(authToken.allowExp)) {
          return '/exposures';
        } else if (JSON.parse(authToken.allowCts)) {
          return '/catastrophic-injuries';
        } else {
          return '/no-access';
        }
      } else if (studyTypeId === StudyTypes.Community) {
        return '/c/match-exposures';
      } else {
        return '/no-access';
      }
    } else if (roles.includes('Analyst')) {
      return '/analyst-dashboard';
    }

    // If unsure, return admin
    return '/admin';
  },
  getEncodedRedirectUriSuffix(toUriPath: any) {
    return !toUriPath || toUriPath === '/' ? '' : `?redirectUri=${encodeURIComponent(toUriPath)}`;
  },
  getDecodedRedirectUri() {
    const param = new URLSearchParams(location.search).get('redirectUri');
    return !!param && param !== '/' ? decodeURIComponent(param) : null;
  },
};
