



































































import { Component } from 'vue-property-decorator';

import AppVue from '@/AppVue.vue';
import { AccountService, ApiService, DownloadProvider, RoutingService } from '@/core/services';

@Component
export default class AuthStatusCpt extends AppVue {
  get organisations() {
    return this.settings?.reporterOrgs;
  }

  get displayName() {
    return this.settings?.fullName ?? this.userName;
  }

  get userRoles() {
    return this.authToken?.userRoles;
  }

  goToPrivacyPolicy() {
    window.open('https://www.world.rugby/privacy-policy');
  }

  goToRequestAccess() {
    this.$router.push('/request-access');
  }

  goToProfile() {
    this.$router.push('/profile');
  }

  goToSmtp4Dev() {
    window.open('http://localhost:32525/');
  }

  goToLocaliseBiz() {
    window.open('https://localise.biz/');
  }

  async logout() {
    await AccountService.logout();

    this.$notify({
      title: this.$t('Success') as string,
      message: this.$t('You are now logged out') as string,
      type: 'success',
    });

    this.$router.push('/login');
  }

  async changeOrganisation(organisationId: number) {
    await AccountService.refreshToken(organisationId);
    this.$router.push(RoutingService.getDefaultRoute(this.authToken)).catch(error => {
      // SEE: https://stackoverflow.com/a/59431264/413785
      if (error.name !== 'NavigationDuplicated') {
        throw error;
      }
    });
    this.$eventHub.$emit('ORGANISATION_CHANGED');
    this.$forceUpdate();
  }
}
