export const adminStudiesRoutes = [
  {
    path: '/admin/studies',
    name: 'Studies',
    component: () => import(/* webpackChunkName: "admin" */ './StudiesGridView.vue'),
  },
  {
    path: '/admin/studies/new',
    name: 'New Study',
    component: () => import(/* webpackChunkName: "admin" */ './StudyFormView.vue'),
  },
  {
    path: '/admin/studies/:studyId/edit',
    name: 'Edit Study',
    component: () => import(/* webpackChunkName: "admin" */ './StudyFormView.vue'),
  },
  {
    path: '/admin/studies/:studyId/guidance/:locale',
    name: 'Study Guidance',
    component: () => import(/* webpackChunkName: "admin" */ './StudyManageGuidanceView.vue'),
  },
];
