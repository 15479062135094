
import { Vue } from 'vue-property-decorator';
import _ from 'lodash';

import { StudyTypes } from './core/constants';

export default class AppVue extends Vue {
  get authToken() {
    return this.$auth.getPayload();
  }

  get isAuthenticated() {
    return this.$auth.isAuthenticated();
  }

  get userName() {
    return this.isAuthenticated ? this.$auth.getPayload().userName : '';
  }

  get currentUserId() {
    return this.authToken?.userId ? parseInt(this.authToken.userId, 10) : null;
  }

  get currentOrganisationId() {
    return this.authToken?.orgId ? parseInt(this.authToken.orgId, 10) : null;
  }

  get currentStudyTypeId() {
    return this.authToken?.stypeId ? parseInt(this.authToken.stypeId, 10) : null;
  }

  get anyReporterStudies() {
    return JSON.parse(this.authToken?.anyStudies?.toLowerCase() ?? false);
  }

  get isAdmin() {
    return this.isInRole('Admin');
  }

  get isStudyAdmin() {
    return this.isInRole('StudyAdmin');
  }

  get isStudyOrSiteAdmin() {
    return this.isInRole('Admin') || this.isInRole('StudyAdmin');
  }

  get isReporter() {
    return this.isInRole('Reporter');
  }

  get isAnalyst() {
    return this.isInRole('Analyst');
  }

  get isMedicalAdmin() {
    return this.isInRole('MedicalAdmin');
  }

  get isMedicalOrSiteAdmin() {
    return this.isInRole('Admin') || this.isInRole('MedicalAdmin');
  }

  get isMedicalOrStudyOrSiteAdmin() {
    return this.isInRole('Admin') || this.isInRole('StudyAdmin') || this.isInRole('MedicalAdmin');
  }

  get environment() {
    return process.env.VUE_APP_ENVIRONMENT;
  }

  get isDevEnv() {
    return this.environment === 'Dev';
  }

  get isTestEnv() {
    return this.environment === 'Test';
  }

  get isProdEnv() {
    return this.environment === 'Production';
  }

  get logoLightFileName() {
    return `${this.branding}.logo.light.png`;
  }

  get logoDarkFileName() {
    return `${this.branding}.logo.dark.png`;
  }

  get branding() {
    return process.env.VUE_APP_BRANDING;
  }

  get isWryBranding() {
    return this.branding === 'wry';
  }

  get isRcsiBranding() {
    return this.branding === 'rcsi';
  }

  get allowInjuryAndIllnessData() {
    return (
      (this.isEliteReporter && JSON.parse(this.authToken?.allowIaI ?? false) && this.anyReporterStudies) ||
      this.isAnalyst
    );
  }

  get allowExposureData() {
    return (
      (this.isEliteReporter && JSON.parse(this.authToken?.allowExp ?? false) && this.anyReporterStudies) ||
      this.isAnalyst
    );
  }

  get allowCatastrophicData() {
    return JSON.parse(this.authToken?.allowCts ?? false) && this.anyReporterStudies;
  }

  get isEliteReporter() {
    return this.isReporter && JSON.parse(this.authToken?.stypeId ?? 0) === StudyTypes.Elite;
  }

  get isCommunityReporter() {
    return this.isReporter && JSON.parse(this.authToken?.stypeId ?? 0) === StudyTypes.Community;
  }

  get isOfflineMode() {
    let isOffline = this.isOnline();

    window.addEventListener('online', () => {
      isOffline = this.isOnline();
      this.$forceUpdate();
    });
    window.addEventListener('offline', () => {
      isOffline = this.isOnline();
      this.$forceUpdate();
    });

    return isOffline;
  }

  get settings() {
    return this.$store.getters.getSettings;
  }

  protected notify(title: string, message: string, type: any) {
    this.$notify({
      title,
      message,
      type,
    });
  }

  protected getUrlParamAsNumber(paramName: string) {
    const id = this.$route.params[paramName];
    return !!id ? parseInt(id, 10) : undefined;
  }

  private isOnline() {
    return !window.navigator.onLine;
  }

  private isInRole(roleName: string) {
    const userRoles = this.authToken?.userRoles;

    if (!userRoles) {
      return false;
    }

    if (Array.isArray(userRoles)) {
      return _.includes(this.authToken.userRoles, roleName);
    }

    if (typeof userRoles === 'string') {
      return userRoles === roleName;
    }

    return false;
  }
}
