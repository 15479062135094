export const adminOrganisationsRoutes = [
  {
    path: '/admin/organisations',
    name: 'Organisations',
    component: () => import(/* webpackChunkName: "admin" */ './OrganisationsGridView.vue'),
  },
  {
    path: '/admin/organisations/new',
    name: 'New Organisation',
    component: () => import(/* webpackChunkName: "admin" */ './OrganisationFormView.vue'),
  },
  {
    path: '/admin/organisations/:organisationId/edit',
    name: 'Edit Organisation',
    component: () => import(/* webpackChunkName: "admin" */ './OrganisationFormView.vue'),
  },
];
