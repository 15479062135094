







import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class DetailsYesNoUnknownCpt extends Vue {
  @Prop() label: string;
  @Prop() value: boolean;

  get nullableValue() {
    if (this.value === null) {
      return 'Unknown';
    }

    return this.value ? 'Yes' : 'No';
  }
}
