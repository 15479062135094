export const authRoutes = [
  {
    path: '/login',
    name: 'login',
    meta: { layout: 'empty', anonymousOnly: true },
    component: () => import(/* webpackChunkName: "auth" */ './LoginView.vue'),
  },
  {
    path: '/complete-registration/:userId/:token',
    name: 'completeRegistration',
    meta: { layout: 'empty', anonymousOnly: true },
    component: () => import(/* webpackChunkName: "auth" */ './CompleteRegistrationView.vue'),
  },
  {
    path: '/forgot-password',
    name: 'forgotPassword',
    meta: { layout: 'empty', anonymousOnly: true },
    component: () => import(/* webpackChunkName: "auth" */ './ForgotPasswordView.vue'),
  },
  {
    path: '/reset-password/:userId/:token',
    name: 'resetPassword',
    meta: { layout: 'empty', anonymousOnly: true },
    component: () => import(/* webpackChunkName: "auth" */ './ResetPasswordView.vue'),
  },
  {
    path: '/authenticator',
    name: 'authenticator',
    component: () => import(/* webpackChunkName: "auth" */ './AuthenticatorView.vue'),
  },
  {
    path: '/no-access',
    name: 'noAccess',
    component: () => import(/* webpackChunkName: "auth" */ './NoAccessView.vue'),
  },
];
