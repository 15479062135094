export const adminUsersRoutes = [
  {
    path: '/admin/users/new',
    name: 'adminUserNew',
    component: () => import(/* webpackChunkName: "admin" */ './UserFormView.vue'),
  },
  {
    path: '/admin/users/:userId/edit',
    name: 'adminUserEdit',
    component: () => import(/* webpackChunkName: "admin" */ './UserFormView.vue'),
  },
  {
    path: '/admin/users/:userId/view',
    name: 'adminUserView',
    component: () => import(/* webpackChunkName: "admin" */ './UserFormView.vue'),
  },
  {
    path: '/profile',
    name: 'profile',
    meta: { hideReporterOrg: true },
    component: () => import(/* webpackChunkName: "admin" */ './ReporterProfileView.vue'),
  },
  {
    path: '/admin/users/:userId/activity',
    name: 'adminUserActivity',
    component: () => import(/* webpackChunkName: "admin" */ './UserEventLogView.vue'),
  },
  {
    path: '/admin/users',
    name: 'adminUserList',
    component: () => import(/* webpackChunkName: "admin" */ './UsersGridView.vue'),
  },
];
