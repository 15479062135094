import { adminUsersRoutes } from './users/routes';
import { adminOrganisationsRoutes } from './organisations/routes';
import { adminStudiesRoutes } from './studies/routes';
import { adminRegistrationRoutes } from './registration/routes';
import { adminApiKeyRoutes } from './api-keys/routes';

export const adminRoutes = [
  {
    path: '/admin',
    name: 'Administration',
    component: () => import(/* webpackChunkName: "admin" */ './AdminView.vue'),
  },
  {
    path: '/analyst-dashboard',
    name: 'Analyst Dashboard',
    component: () => import(/* webpackChunkName: "admin" */ './AnalystDashboardView.vue'),
  },
  {
    path: '/export',
    name: 'Export Data',
    component: () => import(/* webpackChunkName: "admin" */ './ExportView.vue'),
  },
  ...adminUsersRoutes,
  ...adminOrganisationsRoutes,
  ...adminStudiesRoutes,
  ...adminRegistrationRoutes,
  ...adminApiKeyRoutes,
];
