export const reportsInjuriesRoutes = [
  // Elite
  {
    path: '/injuries',
    name: 'injuries',
    component: () => import(/* webpackChunkName: "injuries" */ './EliteInjuriesGridView.vue'),
  },
  {
    path: '/injuries/new/:playerId',
    name: 'injuryNew',
    component: () => import(/* webpackChunkName: "injuries" */ './EliteInjuryReporterFormView.vue'),
  },
  {
    path: '/injuries/new/:autoSaveId/draft',
    name: 'injuryNewDraft',
    component: () => import(/* webpackChunkName: "injuries" */ './EliteInjuryReporterFormView.vue'),
  },
  {
    path: '/injuries/:injuryId',
    name: 'injuryDetails',
    component: () => import(/* webpackChunkName: "injuries" */ './EliteInjuryDetailsView.vue'),
  },
  {
    path: '/injuries/:injuryId/edit',
    name: 'injuryEdit',
    component: () => import(/* webpackChunkName: "injuries" */ './EliteInjuryReporterFormView.vue'),
  },
  {
    path: '/injuries/:injuryId/review',
    name: 'injuryReview',
    component: () => import(/* webpackChunkName: "injuries" */ './EliteInjuryAnalystFormView.vue'),
  },
  // Community
  {
    path: '/c/injuries',
    name: 'communityInjuries',
    component: () => import(/* webpackChunkName: "injuries" */ './CommunityInjuriesGridView.vue'),
  },
  {
    path: '/c/injuries/new',
    name: 'communityInjuryNew',
    component: () => import(/* webpackChunkName: "injuries" */ './CommunityInjuryFormView.vue'),
  },
  {
    path: '/c/injuries/new/:autoSaveId/draft',
    name: 'communityInjuryNewDraft',
    component: () => import(/* webpackChunkName: "injuries" */ './CommunityInjuryFormView.vue'),
  },
  {
    path: '/c/injuries/:injuryId',
    name: 'communityInjuryDetails',
    component: () => import(/* webpackChunkName: "injuries" */ './CommunityInjuryDetailsView.vue'),
  },
  {
    path: '/c/injuries/:injuryId/edit',
    name: 'communityInjuryEdit',
    component: () => import(/* webpackChunkName: "injuries" */ './CommunityInjuryFormView.vue'),
  },
  // Catastrophic
  {
    path: '/catastrophic-injuries',
    name: 'catastrophicInjuries',
    component: () => import(/* webpackChunkName: "injuries" */ './CatastrophicInjuriesGridView.vue'),
  },
  {
    path: '/catastrophic-injuries/new',
    name: 'catastrophicInjuryNew',
    component: () => import(/* webpackChunkName: "injuries" */ './CatastrophicInjuryFormView.vue'),
  },
  {
    path: '/catastrophic-injuries/new/:autoSaveId/draft',
    name: 'catastrophicNewDraft',
    component: () => import(/* webpackChunkName: "injuries" */ './CatastrophicInjuryFormView.vue'),
  },
  {
    path: '/catastrophic-injuries/:injuryId/edit',
    name: 'catastrophicInjuryEdit',
    component: () => import(/* webpackChunkName: "injuries" */ './CatastrophicInjuryFormView.vue'),
  },
  {
    path: '/catastrophic-injuries/:injuryId/follow-up',
    name: 'catastrophicFollowUp',
    component: () => import(/* webpackChunkName: "injuries" */ './CatastrophicFollowUpFormView.vue'),
  },
];
