export enum AutoSaveTypes {
  InjuryReport = 1,
  IllnessReport = 2,
  ExposureReport = 3,
  CommunityInjuryReport = 4,
  CatastrophicInjuryReport = 5,
  CatastrophicFollowUp = 6,
}

export enum ReturnToPlayStatus {
  Unknown = 0,
  NotYetReturned = 1,
  ReturnDayPassed = 2,
  Returned = 3,
}

export enum QcStatus {
  New = 1,
  AwaitingReporter = 2,
  AwaitingAnalyst = 3,
  Complete = 4,
}

export enum StudyTypes {
  Elite = 1,
  Community = 2,
}

export enum GameFormats {
  Fifteens = 1,
  Sevens = 2,
  Multiple = 3,
  Tens = 4,
  NonContact = 5,
  Unknown = 6,
}

export enum InjuryTimes {
  MatchFirstHalf = 7,
  MatchSecondHalf = 8,
}

export const ReporterRequestStatus = {
  Unknown: 0,
  Uninitialized: 1,
  AwaitingAntiSpamCheck: 2,
  AwaitingMedicalAdminApproval: 3,
  AwaitingFinalAdminApproval: 4,
};
