













import { Component, Prop } from 'vue-property-decorator';

import { ValidationMessageCpt, BaseInputComponent } from '.';

@Component({
  components: {
    ValidationMessageCpt,
  },
})
export default class InputSwitchCpt extends BaseInputComponent {
  @Prop() onSwitch: (newValue: boolean) => void;
  @Prop({ default: '' }) activeText: string;
  @Prop({ default: '' }) inactiveText: string;

  get innerActiveText() {
    return this.activeText || (this.$t('Yes') as string);
  }

  get innerInactiveText() {
    return this.inactiveText || (this.$t('No') as string);
  }

  onChangeInternal(newValue: boolean) {
    if (this.onSwitch) {
      this.onSwitch(newValue);
    }
  }
}
