










import { Component } from 'vue-property-decorator';

import AppVue from '@/AppVue.vue';
import { AccountService, ApiService } from '@/core/services';
import { IdleWarningCpt } from '@/core/components';

@Component({
  components: {
    IdleWarningCpt,
  },
})
export default class App extends AppVue {
  internalTimestamp = new Date().toString();
  private isTokenRefreshingInProcess = false;

  // NOTE: Using this to force refresh of the view when a reporter changes Org (this prevents full page refresh)
  //       For example - without this, if user is currently on the default
  //       route for his role, the filters won't refresh automatically
  get timestamp() {
    return this.internalTimestamp;
  }

  async created() {
    // eslint-disable-next-line no-console
    console.log(`${process.env.VUE_APP_ENVIRONMENT} - ${process.env.VUE_APP_VERSION}`);

    this.loadSettings();

    this.$eventHub.$on('USER_UPDATED', () => {
      this.isTokenRefreshingInProcess = false;
      this.loadSettings();
    });

    this.$eventHub.$on('ORGANISATION_CHANGED', () => {
      this.internalTimestamp = new Date().toString();
    });

    this.$eventHub.$on('TOKEN_ABOUT_TO_EXPIRE', () => {
      if (!this.isTokenRefreshingInProcess) {
        this.isTokenRefreshingInProcess = true;
        AccountService.refreshToken(this.currentOrganisationId ?? undefined);
      }
    });
  }

  get layout() {
    return (this.$route.meta?.layout ?? 'default') + '-layout';
  }

  async loadSettings() {
    if (!this.$auth.isAuthenticated()) {
      return;
    }

    this.$store.commit('setSettings', (await ApiService.settings().getSettings())?.data);
  }
}
