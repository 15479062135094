
















import { Component } from 'vue-property-decorator';

import AppVue from '@/AppVue.vue';
import { AccountService, AuthTokenService, RoutingService } from '@/core/services';

@Component
export default class IdleWarningCpt extends AppVue {
  private INACTIVITY_THRESHOLD = (this.isTestEnv ? 27 : 3) * 60 * 1000;
  private KEEP_LOGGED_IN_CHECK_INTERVAL = 1000;
  private remainingTokenSeconds = 0;
  private showDialog = false;
  private idleTimeout: number;
  private logoutCountdownInterval: number;

  async created() {
    this.$eventHub.$on('idleVue_onIdle', () => {
      this.onUiIdle();
    });
    this.$eventHub.$on('idleVue_onActive', () => {
      this.onUiActive();
    });
  }

  get inactivityMessage() {
    return this.$t('You will be logged out in {0} seconds due to inactivity', [this.remainingTokenSeconds]) as string;
  }

  keepLoggedIn() {
    // This is the same action as if the user clicked outside of the
    // modal window or if he closed it by pressing the X button
    this.showDialog = false;
  }

  onClosed() {
    this.clearIdleTimeout();
    this.clearLogoutCountdown();

    if (!this.$auth.isAuthenticated()) {
      this.$notify({
        title: this.$t('Logged out') as string,
        message: this.$t('You were automatically logged out to keep your data secure') as string,
        type: 'info',
        duration: 0,
      });

      this.$router.push(`/login${RoutingService.getEncodedRedirectUriSuffix(this.$route.fullPath)}`);
    } else {
      AuthTokenService.emitTokenAlmostExpiredIfNecessary();
    }
  }

  private onUiIdle() {
    if (this.$auth.isAuthenticated() && !this.showDialog) {
      this.initializeIdleTimeout();
    }
  }

  private onUiActive() {
    this.clearIdleTimeout();
    if (this.$auth.isAuthenticated() && !this.showDialog) {
      // Prevent token refreshing if the modal is visible, it
      // needs to be an explicit confirmation in that case.
      AuthTokenService.emitTokenAlmostExpiredIfNecessary();
    }
  }

  private initializeIdleTimeout() {
    this.clearIdleTimeout();
    this.idleTimeout = setTimeout(() => this.onUserInactiveDetected(), this.INACTIVITY_THRESHOLD);
  }

  private clearIdleTimeout() {
    clearTimeout(this.idleTimeout);
  }

  private onUserInactiveDetected() {
    this.showDialog = true;
    this.initializeLogoutCountdown();
  }

  private initializeLogoutCountdown() {
    this.clearLogoutCountdown();
    this.remainingTokenSeconds = AuthTokenService.getRemainingTokenSeconds();
    this.logoutCountdownInterval = setInterval(() => this.onNoKeepLoggedIn(), this.KEEP_LOGGED_IN_CHECK_INTERVAL);
  }

  private clearLogoutCountdown() {
    this.remainingTokenSeconds = 0;
    clearInterval(this.logoutCountdownInterval);
  }

  private onNoKeepLoggedIn() {
    this.remainingTokenSeconds = AuthTokenService.getRemainingTokenSeconds();
    if (this.remainingTokenSeconds <= 0) {
      AccountService.logout();
      this.showDialog = false;
    }
  }
}
