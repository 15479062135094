




































import { Component } from 'vue-property-decorator';

import AppVue from '@/AppVue.vue';
import { AccountService, RoutingService } from '@/core/services';
import { StudyTypes } from '@/core/constants';

@Component
export default class NoReporterOrganisationMessageCpt extends AppVue {
  organisationId: number | null = 0;
  missingAccessMessage = '';
  isReporterWithNoAccess = false;
  visible = true;

  get organisations() {
    return this.settings?.reporterOrgs;
  }

  get showOrganisationPicker() {
    const isMultiOrgReporter = this.organisations && this.organisations.length > 1;
    return this.organisationId && isMultiOrgReporter;
  }

  get canRequestStudyAccess() {
    const isProfilePage = this.$route.name === 'profile';
    return this.organisationId && isProfilePage && !this.anyReporterStudies;
  }

  get actionMessage() {
    if (this.showOrganisationPicker) {
      return this.canRequestStudyAccess
        ? this.$t(
            'Please request changes on the profile page or switch to another organisation or contact your administrator.',
          )
        : this.$t('Please switch to another organisation or contact your administrator.');
    } else if (this.canRequestStudyAccess) {
      return this.$t('Please request changes on the profile page or contact your administrator.');
    } else {
      return this.$t('Please contact your administrator.');
    }
  }

  async created() {
    this.updateOrgReporterState();

    this.$eventHub.$on('USER_UPDATED', () => {
      this.updateOrgReporterState();
    });
  }

  updateOrgReporterState() {
    this.missingAccessMessage = '';
    this.organisationId = this.currentOrganisationId;

    if (!this.authToken) {
      return;
    }

    if (!this.isReporter) {
      return;
    }

    if (!this.organisationId) {
      this.missingAccessMessage = this.$t('You have not been added to an organisation yet.') as string;
      return;
    }

    const allowIaI = JSON.parse(this.authToken.allowIaI ?? false);
    const allowExp = JSON.parse(this.authToken.allowExp ?? false);
    const allowCts = JSON.parse(this.authToken.allowCts ?? false);
    const dontAllowElite = !allowIaI && !allowExp && !allowCts;

    if (this.currentStudyTypeId === StudyTypes.Elite && dontAllowElite) {
      this.missingAccessMessage = this.$t(
        "You don't have Reporter access to Player (Injury, Illness) or Training (Exposure) data yet.",
      ) as string;
      return;
    }

    if (!this.anyReporterStudies) {
      this.missingAccessMessage = this.$t("You don't have Reporter study access for this organisation yet.") as string;
      return;
    }
  }

  logout() {
    AccountService.logout();

    this.$notify({
      title: this.$t('Success') as string,
      message: this.$t('You are now logged out') as string,
      type: 'success',
    });

    this.$router.push('/login');
  }

  async changeOrganisation(organisationId: number) {
    await AccountService.refreshToken(organisationId);
    this.$router.push(RoutingService.getDefaultRoute(this.authToken));
    this.$forceUpdate();
  }

  close() {
    this.visible = false;
  }
}
