export const playersRoutes = [
  {
    path: '/players',
    name: 'players',
    component: () => import(/* webpackChunkName: "players" */ './PlayersGridView.vue'),
  },
  {
    path: '/players/import',
    name: 'playersImport',
    component: () => import(/* webpackChunkName: "players" */ './PlayersImportView.vue'),
  },
  {
    path: '/players/:playerId',
    name: 'playersDetails',
    component: () => import(/* webpackChunkName: "players" */ './PlayerDetailsView.vue'),
  },
];
